<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-7 left-side">
            <div class="box-top shadow row">
                <div class="col-sm-8">
                    <div class="row box-top-title col-sm-12">Selamat Datang, &nbsp;<b>{{name}}</b></div>
                    <div class="row box-top-desc col-sm-12">Sistem informasi ini dibangun agar Bimbel Taruna Cendekia semakin maju ke depan. Silahkan hubungi admin jika ada kendala atau kesulitan dalam mengoperasikan.</div>
                </div>
                <div class="col-sm-4" style="text-align-last: end;">
                    <img src="@/assets/img/Taruna-maskot-2.png" class="box-top-img" alt="none">
                </div>
            </div>

            <div class="shadow" style="width:100%;min-height: 262px;background:white;padding-left: 31px;padding-top: 24px;border-radius: 20px;margin-bottom: 12px;">
                <div class="row">
                    <div class="col-sm-7">
                        <span style="font-size:18px">Jumlah Siswa Aktif</span>
                        <canvas ref="myChart2" id="siswa-chart" height="400" style="display: block; width: 487px; height: 400px;" width="487" class="chartjs-render-monitor"></canvas>
                    </div>
                    <div class="col-sm-5">
                        <span style="font-size:18px">Kategori Kelas</span>
                        <canvas ref="myChart" id="tentor-chart" height="400" style="display: block; width: 487px; height: 400px;" width="487" class="chartjs-render-monitor"></canvas>
                    </div>
                </div>
            </div>
            <div class="shadow" style="width:100%;min-height: 262px;background:white;padding-left: 31px;padding-top: 24px;border-radius: 20px;" v-if="false">
                <span style="font-size:18px">Keaktifan Siswa</span>
                <canvas ref="chartscater" id="keaktifan-chart" height="400" style="display: block; width: 487px; height: 400px;" width="487" class="chartjs-render-monitor"></canvas>
            </div>
        </div>
        <div class="col-sm-5" style="padding-left: 24px;">
            <div class="right-side">
                <span style="font-size:18px">Kalender Akademik</span>
                <div class="row">
                    <div id="myCal"></div>
                </div>
                <div class="row" style="margin-bottom:12px">
                    <div class="col-sm-6" style="font-size:18px;align-self: center;">Agenda Terdekat</div>
                    <div class="col-sm-6" style="text-align: right;">
                        <router-link class="btn" style="background:#EC008C;color: white;" to="/lesson-plan">Lihat Semua</router-link>
                        </div>
                </div>
                <div class="row">
                    <!-- <div class="tes"  > -->
                    <div class="col-sm-12" v-for="row in agenda">
                        <div class="callout callout-info">
                            <div class="row">
                                <div class="col-sm-8">
                                  <h5 style="font-size:14px;font-weight:600;color: #7D7D7D;">{{ row.materi }} - {{row.name}}</h5>
                                  <p style="font-size:14px;font-weight:400;color: #9590A0;">{{row.date}} {{row.time_start}}</p>
                                </div>
                                <div class="col-sm-4" style="text-align: -webkit-right;">
                                    <div class="event-circle">
                                        <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '42px', color:'white'}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        ref="formDialog"
        data-backdrop="static"
        >
        <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 form-group">
                    <label class="control-label">Tanggal Awal</label>
                    <h5>{{form.start}}</h5>
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">Tanggal Akhir</label>
                    <h5>{{form.end}}</h5>
                    </div>
                    <div class="col-md-12 form-group">
                    <label class="control-label">Agenda</label>
                    <h5>{{form.name}}</h5>
                    <!-- <label class="control-label">Agenda : {{form.name}}</label> -->
                    </div>
                </div>
                </div>
                <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Close
                </button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import {auth, authFetch} from '@/libs/hxcore';
    import Calendar from 'color-calendar';
    import "color-calendar/dist/css/theme-glass.css";
    import IconifyIcon from '@iconify/vue';
    import activityFill from '@iconify/icons-eva/activity-fill';
    import Chart from 'chart.js';
    import $ from 'jquery';
    import moment from "moment";
    var chartSiswa, chartTentor, chartKeaktifan;
    var myChart = null;
    var myChart2 = null;

    export default {
        name: 'AkademikDashboard',
        components: {
            IconifyIcon
        },
        data() {
            return {
                icons: {
                    activityFill
                },
                name : '',
                imgUrl : '',
                agenda : [],
                events : [],
                form: {
                    start:'',
                    end:'',
                    name:'',
                },
                // dataKalender: {
                //     id: "#myCal",
                //     theme: "glass",
                //     dateChanged: (currentDate, events) => {
                //         console.log(events);
                //     },
                //     // eventsData: [],
                //     // // eventsData: [
                //     // //     {
                //     // //         start: '2021-10-17T06:00:00',
                //     // //         end: '2021-10-18T20:30:00',
                //     // //         name: 'Blockchain 101'
                //     // //     },
                //     // // ]
                //     eventsData: []
                // },
                dataTentor: {
                    type: "doughnut",
                    data: [],
                    options: {
                    responsive: true,
                    plugins: {
                        legend: {
                        position: "top",
                        },
                        title: {
                        display: true,
                        text: "",
                        },
                    },
                    },
                },
                dataSiswa: {
                    type: "bar",
                    data: [],
                    options: {
                    responsive: true,
                    plugins: {
                        legend: {
                        position: "top",
                        },
                        title: {
                        display: true,
                        text: "",
                        },
                    },
                    },
                },
            };
        },
        created: function() {
            this.roles = this.$route.meta.roles;
            var info = auth.user();
            console.log('user', info)
            if(info)
            {
                this.name = info.display_name;
                this.imgUrl = info.img_url;
            }
            else{
                this.imgUrl = '/dist/img/user.jpeg';
            }

            this.tentorAktif();
            this.siswaAktif();
            this.agendaTerdekat();
            // this.kalenderAkademik();
        },
        methods: {
            tentorAktif() {
                authFetch(`/dashboard/statistic`)
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    this.dataTentor.data = js.pieChart;
                    myChart.update();
                });
            },
            siswaAktif() {
                authFetch(`/dashboard/siswa`)
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    this.dataSiswa.data = js.barChart;
                    myChart2.update();
                });
            },
            agendaTerdekat() {
                authFetch(`/dashboard/agenda`)
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    this.agenda = js.data;
                });
            },
            loadKalender(events){
                new Calendar({
                id: "#myCal",
                theme: "glass",
                dateChanged: (currentDate, events) => {
                    // console.log(currentDate);
                    // console.log(events);
                    if (events.length < 1) {
                        // console.log(currentDate);
                        return;
                    } else {
                        let start = events[0].start;
                        let end = events[0].end;
                        this.form.start = moment(start.split("T")[0]).format("DD/MM/YYYY");
                        this.form.end = moment(end.split("T")[0]).format("DD/MM/YYYY");
                        this.form.name = events[0].name;
                        $(this.$refs.formDialog).modal("show");
                    }
                },
                eventsData: events
                });
            },
        },
        mounted() {
            // new Calendar(this.dataKalender);
            const self = this;

            let datak = [];

            authFetch(`/dashboard/kalender`)
            .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
            })
            .then((js) => {
                this.events = js.data;
                this.loadKalender(this.events);
            });
            // let datak = [
            //     {
            //         start: '2021-10-17T06:00:00',
            //         end: '2021-10-18T20:30:00',
            //         name: 'Blockchain 101'
            //     },
            //     {
            //         start: '2021-10-11T06:00:00',
            //         end: '2021-10-13T20:30:00',
            //         name: 'Blockchain 101'
            //     },
            // ];

            var ctx = this.$refs.myChart.getContext("2d");
            var ctx2 = this.$refs.myChart2.getContext("2d");

            myChart = new Chart(ctx, this.dataTentor);
            myChart2 = new Chart(ctx2, this.dataSiswa);

            // var ctx = document.getElementById('siswa-chart').getContext('2d')

            // chartSiswa = new Chart(ctx, {
            //     type: 'bar',
            //     data: {
            //         labels: [
            //           "January",
            //           "February",
            //           "March",
            //           "April",
            //           "May",
            //           "June"
            //         ],
            //         datasets: [
            //           {
            //             label: "Data One",
            //             backgroundColor: "#f87979",
            //             data: [40, 20, 12, 39, 10, 40]
            //           }
            //         ]
            //       },
            //     options: {
            //         responsive: true,
            //         legend: {
            //             position: 'top',
            //         },
            //         title: {
            //             display: true,

            //         }
            //     }
            // });

            // var ctx2 = document.getElementById('tentor-chart').getContext('2d')

            // chartTentor = new Chart(ctx2, {
            //     type: 'doughnut',
            //     data: {
            //         labels: [
            //           "January",
            //           "February",
            //           "March",
            //           "April"
            //         ],
            //         datasets: [
            //           {
            //             label: "Data One",
            //             backgroundColor: "#f87979",
            //             data: [40, 20, 12, 39]
            //           }
            //         ]
            //       },
            //     options: {
            //         responsive: true,
            //         legend: {
            //             position: 'top',
            //         },
            //         title: {
            //             display: true,

            //         }
            //     }
            // });

            var ctx3 = document.getElementById('keaktifan-chart').getContext('2d')

            chartKeaktifan = new Chart(ctx3, {
                type: 'line',
                data: {
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April"
                    ],
                    datasets: [
                      {
                        label: "Data One",
                        backgroundColor: "#f87979",
                        data: [40, 20, 12, 39],
                        fill: false,
                      },
                      {
                        label: "Data Two",
                        backgroundColor: "rgb(75, 192, 192)",
                        data: [30, 12, 24, 31],
                        fill: false,
                      },
                      {
                        label: "Data Three",
                        backgroundColor: "#F1CC19",
                        data: [20, 11, 18, 25],
                        fill: false,
                      }
                    ]
                  },
                options: {
                    responsive: true,
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,

                    }
                }
            });
        }
    }
</script>

<style type="text/css" scoped>
    .box-top {
        min-height: 141px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 32px;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    .box-top-title {
        padding-top: 17px;
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-size: 24px;
    }

    .box-top-desc {
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #7D7D7D;
    }

    .shadow {
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 4%) !important;
    }

    .custom-container {
        padding: 31px 31px 0px 31px;
        height: 100%;
    }

    .items {
        padding-left: 32px;
        padding-top: 25px;
        margin-left: 12px;
        margin-bottom: 21px;
        min-width: 220px;
        background-color: white;
        border-radius: 20px;
        min-height: 210px;
    }

    .items-circle {
        width: 76px;
        height: 76px;
        background: linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%);
        border-radius: 50%;
        margin-bottom: 12px;
        text-align: center;
    }

    .items-title {
        height: 40px;
        padding-top : 4px;
        padding-bottom : 4px;
        font-weight: 700;
        font-size: 24px;
        color: #363636;
    }

    .items-desc {
        padding-right: 30px;
    }

    .items-icon {
        transform: translateY(50%);
        -ms-transform: translateY(50%);
    }

    .box-top-img {
    }

    /*right side*/
    .right-side {
        background: white;
        border-radius: 10px;
        padding: 26px 19px 0px 22px;
        height: 100%;
    }

    #myCal {
        margin-top: 25px;
        margin-bottom: 65px;
        width: 100%;
        text-align-last: center;
    }

    .event-circle {
        width:48px;
        height: 48px;
        background: #C8372D;
        border-radius: 50%;
    }

    .callout-danger {
        background: #EBEBEB;
    }

    .callout.callout-danger {
        border-left: 8px #C8372D solid;
        border-radius: 10px;
    }

    .callout-warning {
        background: #EBEBEB;
    }

    .callout.callout-warning {
        border-left: 8px #FCA702 solid;
        border-radius: 10px;
    }

    .color-calendar {
        font-size: 0.7rem;
    }
</style>